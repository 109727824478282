.slider-thumb {
    border: 3px solid #EC5C57;
    padding: 4px 2px;
    border-radius: 50px;
    font-size: 16px;
    background: #fff6f5;
    color: #fff6f5;
    z-index: 10;
    bottom: -33px;
    width: 60px;
    height: 56px;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.05);
    border: 1px solid var(--light-red);
    cursor: pointer;
}

.react-slider {
    width: 80%;
    margin: 30px auto;
    background: #EC5C57;
    display: flex;
    align-items: center;
}

.slider-track {
    background: #EC5C57;
    border-radius: 20px;
    height: 16px;
}

.slider-track-1 {
    background: #F2F2F2;
}

.slider-thumb:focus{
    outline: none;
}