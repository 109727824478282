@font-face {
  font-family: 'Poppins';
  src: url('../assets/fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'HeroicCondensed';
  src: url('../assets/fonts/HeroicCondensed-Medium.ttf');
}

html {
  height: 100%;
  font-family: 'Poppins' , 'Roboto';
  -webkit-tap-highlight-color:transparent;
}

body {
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

:root {
  --red: #EC5C57;
  --light-red: #EC5C57;
  --pink: #FFF6F5;
  --orange: #F29243;
  --yellow: #F3D54F;
  --green: #1BA14A;
  --gray: #C4C4C4;
  --black: #222222;
  --dark: #000000;
  --neutral-90p: #1e1f1c;
  --neutral-80p: #232323;
  --neutral-70p: #343434;
  --neutral-60p: #565656;
  --neutral-50p: #848484;
  --neutral-40p: #c6c6c6;
  --neutral-30p: #d8d8d8;
  --neutral-20p: #f2f2f2;
}

.my-facebook-button-class {
  width: 275px;
  height: 50px;
  background-color: #1877F2;
  color: white;
  font-family: "Helvetica";
  font-size: 20px;
  font-weight: 700;
  border-radius: 10px;
  border: none;
  box-shadow: rgb(0 0 0 / 25%) 0px 2px 4px 0px;
  cursor: pointer;
}

li {
  text-align: center;
  margin: 10px 0px;
}
.my-facebook-button-class:hover{
  box-shadow: rgb(66 133 244 / 30%) 0px 0px 3px 3px;
}


.my-facebook-button-class > i {
    margin-right: 24px;
}

#my-google-button > span {
  margin-left: -24px;
}

.react-pdf__Page__canvas {
    display: block;
    user-select: none;
    width: 100% !important;
    height: calc(100% - 78px) !important;
}

@media (max-width: 768px) {
    .cancel-position-absolute {
      position: initial !important;
  }
}